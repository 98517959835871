// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.top-click-card {
  width: 290px;
  border-radius: 6px;
}

.top-click-card a {
  position: relative;
  width: 290px;
  border-radius: 6px;
}

.top-click-card-title {
  bottom: 12px;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  left: 15px;
  max-width: 260px;
  position: absolute;
}
`, "",{"version":3,"sources":["webpack://./src/components/topclickcard.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,eAAe;EACf,gBAAgB;EAChB,UAAU;EACV,gBAAgB;EAChB,kBAAkB;AACpB","sourcesContent":[".top-click-card {\n  width: 290px;\n  border-radius: 6px;\n}\n\n.top-click-card a {\n  position: relative;\n  width: 290px;\n  border-radius: 6px;\n}\n\n.top-click-card-title {\n  bottom: 12px;\n  color: #fff;\n  font-size: 16px;\n  font-weight: 500;\n  left: 15px;\n  max-width: 260px;\n  position: absolute;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
