import React from "react";

import "./topclickcard.css";

const TopClicksCard = () => {
  return (
    <div className="top-click-card">
      <a href="#">
        <img
          src="https://www.worthepenny.com/wep-img/VRLWy6OqJWaba67WIVXg4Tm11mE=/500x350/upload/blogs/44dc96d1c13e4d60b6a8097a8ea59cb0.jpg"
          alt=""
          srcset=""
        />
        <p className="top-click-card-title">
          Top 5 Nude Eye Shadow Recommendations{" "}
        </p>
      </a>
    </div>
  );
};

export default TopClicksCard;
