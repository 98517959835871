import React, { useEffect, useState } from "react";
import ProductCard from "./ProductCard";

import "./productlist.css";
import axios from "axios";

const ProductList = () => {
  const [deals, setDeals] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const res = await axios.get("https://apis.armezon.com/v1/deals");

        setDeals(res.data);

        console.log(res.data);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  return (
    <section id="all-deals">
      <div className="flex justify-between items-center mb-2">
        {" "}
        <h3 className="text-xl">Deals That Make Every Penny Count</h3>
        {/* <a href="#">View More {">"}</a> */}
      </div>

      <div className="product-list">
        {deals.map((deal) => (
          <ProductCard deal={deal} />
        ))}
      </div>
    </section>
  );
};

export default ProductList;
