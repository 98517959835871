import React, { useEffect, useState } from "react";
import image1 from "../assets/beauty_banner_1.jpg";
import image2 from "../assets/freezer_banner_1.jpg";
import image3 from "../assets/home_appliance_banner_1.jpg";
import image4 from "../assets/industrial_banner_1.jpg";
import image5 from "../assets/smart_tvs_banner.jpg";
import image6 from "../assets/sports_banner_1.png";
import Deal1 from "../assets/Image/image1.webp";
import Deal2 from "../assets/Image/image2.webp";
import Deal3 from "../assets/Image/image3.webp";
import Deal4 from "../assets/Image/image4.webp";
import Deal5 from "../assets/Image/image5.webp";
import Deal6 from "../assets/Image/image6.webp";
import Deal7 from "../assets/Image/image7.webp";
import Deal8 from "../assets/Image/image8.webp";
import Deal9 from "../assets/Image/img9.svg";
import Picks1 from "../assets/category/apparel.png";
import Picks2 from "../assets/category/beauty.png";
import Picks3 from "../assets/category/furniture.png";
import Picks4 from "../assets/category/home_appliance.png";
import Picks5 from "../assets/category/home_improvement.png";
import Picks6 from "../assets/category/industrial.png";
import Picks7 from "../assets/category/kitchen_appliance.png";
import Picks8 from "../assets/category/luggage.png";
import "../style/carousel.css";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // import the CSS
import axios from "axios";

const BannerCarousel = () => {
  const [categories, setCategories] = useState([]);
  const [topDeals, setTopDeals] = useState([]);

  console.log("cat>>>>>>>>>>", categories);

  useEffect(() => {
    (async () => {
      try {
        const res = await axios.get("https://apis.armezon.com/v1/category");

        setCategories(res.data);

        // console.log(res.data);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const res = await axios.get(
          "https://apis.armezon.com/v1/deals?type=top_deal&limit=10"
        );

        setTopDeals(res.data);

        console.log(res.data);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  return (
    <>
      <div className="banner-carousel-container mt-44 md:mt-28">
        <Carousel
          autoPlay={false}
          infiniteLoop={false}
          showThumbs={false}
          showStatus={false}
          showIndicators={true}
          interval={2000}
          transitionTime={500}
        >
          <div className="carousel-image-container">
            <img
              className="carousel-section-one"
              src={image1}
              alt="First slide"
            />
          </div>
          <div className="carousel-image-container">
            <img
              className="carousel-section-one"
              src={image2}
              alt="Second slide"
            />
          </div>
          <div className="carousel-image-container">
            <img
              className="carousel-section-one"
              src={image3}
              alt="Third slide"
            />
          </div>
          <div className="carousel-image-container">
            <img
              className="carousel-section-one"
              src={image4}
              alt="Fourth slide"
            />
          </div>
          <div className="carousel-image-container">
            <img
              className="carousel-section-one"
              src={image5}
              alt="Fifth slide"
            />
          </div>
          <div className="carousel-image-container">
            <img
              className="carousel-section-one"
              src={image6}
              alt="Sixth slide"
            />
          </div>
        </Carousel>
      </div>
      {/* start the hand tested code */}
      <div className="main-hand-tested-code">
        <div className=""><center className="text-2xl font-semibold">Categories</center></div>
        <div className="flex justify-center items-center gap-8 mb-8 flex-wrap">
          {categories.map((cat) => {
            return (
              <a
                className="flex flex-col justify-center items-center gap-3 bg-[#fff] p-5 rounded-xl shadow-md w-[210px] hover:bg-[#f7f7f7] border"
                href={`/category/${cat.title}`}
                key={cat._id}
              >
                <div className="round-section-image-box">
                  <img
                    src={"https://apis.armezon.com/" + cat.image}
                    alt="img"
                  />
                </div>
                <div className="round-section-text">{cat.title}</div>
              </a>
            );
          })}
        </div>
      </div>
      {/* end the hand tested code */}
      {/* start the hottest picks */}
      <div className="mt-10"><center className="text-2xl font-semibold">Today’s Top Deals</center></div>
      <div className="hottest-main-container">
        <div className="flex justify-center items-center gap-5 flex-wrap text-center">
          {topDeals.map((deal) => {
            return (
              <div className="relative  border border-[#99a0ac] pt-5 pr-3 pb-3 pl-3 rounded-lg flex flex-col items-center gap-y-[14px] w-[250px] h-[350px]">
                <div className="hottest-picks-image-container">
                  {/* <img
                    className="hottest-picks-image"
                    src={"https://apis.armezon.com/" + deal.image}
                    alt="img1"
                  /> */}
                  <div
                    className="hottest-picks-image"
                    style={{
                      backgroundImage: `url('https://apis.armezon.com/${deal.image}')`,
                    }}
                  />
                </div>
                <div className="border border-[#c1c1c1] mt-2 flex justify-center items-center py-1 px-2.5 text-white text-xs font-medium rounded cursor-pointer">{deal.offer}</div>
                <div className="hottest-pickes-deal-heading">
                  {deal.deal_from}
                </div>
                <div className="hottest-pickes-deal-contents">
                  {deal.description}
                </div>
                <div className="hottest-pickes-get-button-section">
                  <a href={deal.link} target="_blank" className="flex justify-center">
                    <span className="absolute  bottom-3 bg-primary-yellow py-1.5 px-3.5 text-[white] text-sm font-medium rounded-md cursor-pointer">Get Deal</span>
                  </a>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {/* end the hottest picks */}
    </>
  );
};

export default BannerCarousel;
