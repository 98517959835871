import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import ProductCard from "../components/ProductCard";

const Category = () => {
  const [deals, setDeals] = useState([]);

  let { cat } = useParams();

  useEffect(() => {
    (async () => {
      try {
        const res = await axios.get(`https://apis.armezon.com/v1/deal/${cat}`);

        setDeals(res.data);

        console.log(res.data);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [cat]);

  return (
    <div className="mt-44 md:mt-28 mb-10">
      <center className="text-3xl font-semibold mb-5">{cat}</center>
      <div className="flex flex-col items-center gap-10 px-10">
        {deals.map((deal) => (
          <ProductCard deal={deal} />
        ))}
      </div>
    </div>
  );
};

export default Category;
