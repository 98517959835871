import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "../src/components/Header.js";
import Footer from "../src/components/Footer.js";
import "@fortawesome/fontawesome-free/css/all.min.css";
import BannerCarousel from "./components/BannerCarousel.js";
import ProductList from "./components/ProductList.jsx";
import TopClicksList from "./components/TopClicksList.jsx";
import Home from "./pages/Home.jsx";
import Category from "./pages/Category.jsx";
import Search from "./pages/Search.jsx";

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/search/:title" element={<Search />} />
        <Route path="/category/:cat" element={<Category />} />
      </Routes>

      <Footer />
    </Router>
  );
}

export default App;
