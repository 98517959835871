import React from "react";
import { FaHeart } from "react-icons/fa";
import { AiOutlineComment } from "react-icons/ai";

import "./productcard.css";

const ProductCard = ({ deal }) => {
  return (
    <div className="product-card shadow-md">
      <div className="product-card-left border-r-0 md:border-r md:border-r-[#e8e8e8] border-b border-b-[#e8e8e8] md:border-b-0">
        {/* <img src={"https://apis.armezon.com/" + deal.image} alt="" srcset="" /> */}
        <div
          className="product_image"
          style={{
            backgroundImage: `url('https://apis.armezon.com/${deal.image}')`,
          }}
        />
      </div>
      <div className="product-card-right" style={{ width: "100%" }}>
        <div className="product-card-top-meta">
          <div className="seller">{deal.deal_from}</div>
          <div className="date">Updated July 29, 2024</div>
        </div>
        <h3>{deal.title}</h3>
        <div className="price-sec">
          <div className="price">{deal.discount_price}</div>
          <div className="price-mrp">{deal.original_price}</div> |
          <div className="discount-tag">{deal.offer}</div>
        </div>
        <div>
          <p className="description">{deal.description}</p>
        </div>
        <div className="product-card-footer">
          <div className="review-btns">
            {/* <button>
              <FaHeart /> 0
            </button>
            <button>
              <AiOutlineComment /> 1
            </button> */}
          </div>
          <button className="bg-primary-yellow rounded text-[white] text-[15px] font-medium h-10 leading-[18px] w-[160px] mt-2.5">Get Deal</button>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
