import React, { useState } from "react";
import "../style/Header.css"; // Assuming you have a CSS file for the styles
// import "boxicons/css/boxicons.min.css";
import { IoSearchOutline } from "react-icons/io5";
import { FaShoppingCart } from "react-icons/fa";
import { useNavigate } from "react-router";

const Header = () => {
  const [isMenuActive, setIsMenuActive] = useState(false);
  const [searchText, setSearchText] = useState("");

  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsMenuActive(!isMenuActive);
  };

  const closeMenu = () => {
    setIsMenuActive(false);
  };

  return (
    <header className="header" id="header">
      <nav className="navbar container">
        <a href="/" className="brand">
          <img
            style={{ width: "150px" }}
            src="/img/a_logo.png"
            alt="Brand Logo"
            className="brand-logo"
          />
        </a>
        <div className="search">
          <form
            className="search-form"
            onSubmit={(e) => {
              e.preventDefault();
              navigate(`/search/${searchText}`);
            }}
          >
            <IoSearchOutline size={25} />
            <input
              type="text"
              name="search"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              className="search-input"
              placeholder="Search for best deals"
              autoFocus
            />
            <button type="submit" className="search-submit">
              <i className="bx bx-search"></i>
            </button>
          </form>
        </div>
        <div className={`menu ${isMenuActive ? "is-active" : ""}`} id="menu">
          <ul className="menu-inner">
            {/* <li className="menu-item">
              <a href="#" className="menu-link" onClick={closeMenu}>Home</a>
            </li> */}
            <li className="menu-item">
              <a href="#all-deals" className="menu-link" onClick={closeMenu}>
                Deals
              </a>
            </li>
            <li className="menu-item">
              <a href="#" className="menu-link" onClick={closeMenu}>
                Categories
              </a>
            </li>
            <li className="menu-item">
              <a href="#" className="menu-link" onClick={closeMenu}>
                Coupons
              </a>
            </li>
            <li className="menu-item">
            <FaShoppingCart className="cursor-pointer" color="white" size={25} />
            </li>
          </ul>
        </div>
        <div
          className={`burger ${isMenuActive ? "is-active" : ""}`}
          id="burger"
          onClick={toggleMenu}
        >
          <span className="burger-line"></span>
          <span className="burger-line"></span>
          <span className="burger-line"></span>
        </div>
      </nav>
    </header>
  );
};

export default Header;
