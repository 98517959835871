import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import ProductCard from "../components/ProductCard";

const Search = () => {
  const [deals, setDeals] = useState([]);

  let { title } = useParams();

  useEffect(() => {
    (async () => {
      try {
        const res = await axios.get(
          `https://apis.armezon.com/v1/deals?search=${title}`
        );

        console.log("search", res);

        if (!res.data) {
          setDeals([]);
        }

        setDeals(res.data);

        //   console.log(res.data);
      } catch (error) {
        console.log(error);
        setDeals([]);
      }
    })();
  }, [title]);

  return (
    <div className="mt-44 md:mt-28 mb-10">
      <div className="flex flex-col items-center gap-10 px-10">
        {deals.length > 0 ? (
          deals.map((deal) => <ProductCard deal={deal} />)
        ) : (
          <div className="min-h-[400px] flex justify-center items-center">
            <center className="text-2xl font-bold">No data found!</center>
          </div>
        )}
      </div>
    </div>
  );
};

export default Search;
