import React from "react";
import ProductList from "../components/ProductList";
import TopClicksList from "../components/TopClicksList";
import BannerCarousel from "../components/BannerCarousel";

const Home = () => {
  return (
    <>
      <BannerCarousel />
      <div
        style={{
          maxWidth: "1200px",
          margin: "0 auto",
          display: "flex",
          justifyContent: "space-between",
          marginTop: "80px",
        }}
      >
        <ProductList />
        {/* <TopClicksList /> */}
      </div>
    </>
  );
};

export default Home;
