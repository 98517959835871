import React from "react";
import TopClicksCard from "./TopClicksCard";

const TopClicksList = () => {
  return (
    <div className="top_click_list">
      <h3
        style={{
          marginBottom: "16px",
        }}
      >
        Top Clicks
      </h3>
      <div
        style={{
          display: "flex",
          gap: "12px",
          flexDirection: "column",
        }}
      >
        <TopClicksCard />
        <TopClicksCard />
        <TopClicksCard />
      </div>
    </div>
  );
};

export default TopClicksList;
