// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.product-list {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.product-list-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

@media (max-width: 767px) {
  .top_click_list {
    display: none;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/productlist.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE;IACE,aAAa;EACf;AACF","sourcesContent":[".product-list {\n  display: flex;\n  flex-direction: column;\n  gap: 18px;\n}\n\n.product-list-header {\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: 16px;\n}\n\n@media (max-width: 767px) {\n  .top_click_list {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
