import React from "react";
import "../style/Footer.css"; // Import the CSS file
import { RiSendPlaneFill } from "react-icons/ri";

const Footer = () => {
  return (
    <footer className="footer-section">
      <div className="container">
        
        <div className="footer-content pt-5 pb-5">
          <div className="row">
            <div className="footer-col">
              <div className="footer-widget">
                <div className="footer-logo">
                  <a href="#">
                    <img
                      style={{ width: "150px" }}
                      src="/img/a_logo.png"
                      alt="Brand Logo"
                      className="brand-logo"
                    />
                  </a>
                </div>
                <div className="footer-text">
                  <p>
                  Your one-stop online shop for everything you need, from electronics to daily essentials, with fast delivery and unbeatable prices. Shop smarter, live better!
                  </p>
                </div>
                <div className="footer-social-icon mt-2">
                  <span>Follow us</span>
                  <a href="#">
                    <i className="fab fa-facebook-f facebook-bg"></i>
                  </a>
                  <a href="#">
                    <i className="fab fa-twitter twitter-bg"></i>
                  </a>
                  <a href="#">
                    <i className="fab fa-google-plus-g google-bg"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="footer-col">
              <div className="footer-widget">
                <div className="footer-widget-heading">
                  <h3>Useful Links</h3>
                </div>
                <ul>
                  <li>
                    <a href="#">Deals</a>
                  </li>
                  <li>
                    <a href="#">Categories</a>
                  </li>
                  <li>
                    <a href="#">Coupon</a>
                  </li>
                  {/* <li>
                    <a href="#">Blog</a>
                  </li> */}
                  {/* <li><a href="#">Contact</a></li> */}
                </ul>
              </div>
            </div>
            <div className="footer-col">
              <div className="footer-widget">
                <div className="footer-widget-heading">
                  <h3>Subscribe</h3>
                </div>
                <div className="footer-text mb-25">
                  <p>Subscribe to get hand-picked deals direct to your inbox</p>
                </div>
                <div className="subscribe-form">
                  <form action="#" className="flex items-center">
                    <input type="text" placeholder="Email Address" />
                    <button className="">
                    <RiSendPlaneFill size={30} />
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright-area">
        <div className="container">
          <div className="row">
            <div className="col-half text-center text-lg-left">
              <div className="copyright-text">
                <p>
                  Copyright &copy; 2024, All Right Reserved <a href="/">Armezon</a>
                </p>
              </div>
            </div>
            <div className="col-half text-right">
              <div className="footer-menu">
                <ul>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <a href="#">Terms</a>
                  </li>
                  <li>
                    <a href="#">Privacy</a>
                  </li>
                  <li>
                    <a href="#">Policy</a>
                  </li>
                  <li>
                    <a href="#">Contact</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
